import React, { useEffect, useState } from "react";
import { UsersTable } from "../components/UsersTable";
import { Box } from "@mui/system";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LogoutIcon from "@mui/icons-material/Logout";
import UpdateIcon from '@mui/icons-material/Update';
import useAuth from "../hooks/useAuth";
import { listAllUsers } from "../api/users";
import { FormModal } from "../components/FormModal";
import { SuccessAlert } from "../components/SuccessAlert";
import { DisableModal } from "../components/DisableModal";
import { EnableModal } from "../components/EnableModal";
import { uploadPedidos } from "../api/pedidos";
import { DeleteModal } from "../components/DeleteModal";

const Home = () => {
  const { logout } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [msg, setMsg] = useState(null);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUserIdAndLabel, setSelectedUserIdAndLabel] = useState(null);
  const [openEnableModal, setOpenEnableModal] = useState(false);

  const getAllUsers = async () => {
    const response = await listAllUsers();
    setUsers(response.data);
  };

  const updatePedidos = async () => {
    const response = await uploadPedidos()
    if(response) {
      setMsg(response.msg)
      setOpenAlert(true)
    }
  }

  useEffect(() => {
    getAllUsers();
  }, [openModal, openDisableModal, openEnableModal, openDeleteModal]);

  useEffect(() => {
    if (msg) setOpenAlert(true);
  }, [msg]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src="/logo.png"
          alt="Rueddo"
          width={150}
          style={{ margin: 20 }}
        />
      </Box>
      <UsersTable
        data={users}
        setOpenModal={setOpenModal}
        setSelectedUserData={setSelectedUserData}
        setOpenDisableModal={setOpenDisableModal}
        setOpenDeleteModal={setOpenDeleteModal}
        setSelectedUserIdAndLabel={setSelectedUserIdAndLabel}
        setOpenEnableModal={setOpenEnableModal}
      />
      <Fab
        color="primary"
        aria-label="new-client"
        size="small"
        sx={{ position: "absolute", top: 15, right: 130 }}
        onClick={() => {
          setOpenModal(true);
          setSelectedUserData(null);
        }}
      >
        <AddIcon />
      </Fab>
      {/* <Fab
        color="primary"
        aria-label="upload-pedidos"
        size="small"
        sx={{ position: "absolute", top: 15, right: 80 }}
        onClick={updatePedidos}
      >
        <UpdateIcon />
      </Fab> */}
      <Fab
        color="#000"
        aria-label="logout"
        size="small"
        sx={{ position: "absolute", top: 15, right: 30 }}
        onClick={logout}
      >
        <LogoutIcon />
      </Fab>
      <FormModal
        open={openModal}
        setOpen={setOpenModal}
        setMsg={setMsg}
        selectedUserData={selectedUserData}
      />
      <DisableModal
        openDisableModal={openDisableModal}
        setOpenDisableModal={setOpenDisableModal}
        selectedUserIdAndLabel={selectedUserIdAndLabel}
        setSelectedUserIdAndLabel={setSelectedUserIdAndLabel}
        selectedUserData={selectedUserData}
        setSelectedUserData={setSelectedUserData}
      />
      <EnableModal
        openEnableModal={openEnableModal}
        setOpenEnableModal={setOpenEnableModal}
        selectedUserData={selectedUserData}
        setSelectedUserData={setSelectedUserData}
      />
      <DeleteModal
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        selectedUserIdAndLabel={selectedUserIdAndLabel}
        setSelectedUserIdAndLabel={setSelectedUserIdAndLabel}
      />
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <SuccessAlert
          open={openAlert}
          setOpen={setOpenAlert}
          msg={msg}
          setMsg={setMsg}
        />
      </Box>
    </Box>
  );
};

export default Home;
