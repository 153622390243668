import React from "react";
import useAuth from "../hooks/useAuth";
import Home from "../pages/Home";
import Login from '../pages/Login'

export const Router = () => {
  const { user } = useAuth();

  return (user && user.loggedIn && user.isActive && (user.rol === 'ADMIN')) ? <Home /> : <Login />;
};
