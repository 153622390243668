import * as React from "react";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Slide } from "@mui/material";
import { Box } from "@mui/system";
import useAuth from "../hooks/useAuth";

export const ErrorAlert = () => {
  const { authError, setAuthError } = useAuth();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if(authError) setOpen(true)
  }, [authError])

  return (
    <Box sx={{ position: "absolute", top: 50 }}>
      <Slide in={open} direction="down">
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
                setAuthError(null)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {authError}
        </Alert>
      </Slide>
    </Box>
  );
};
