

const BASE_URL = process.env.REACT_APP_API_URL;
// export const BASE_URL = 'https://servidor-rueddo';

const API_PATH = `${BASE_URL}/api`;

export const AUTH_PATH = `${API_PATH}/auth`

export const USERS_PATH = `${API_PATH}/users`

export const PEDIDOS_PATH = `${API_PATH}/pedidos`