import * as React from "react";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Slide } from "@mui/material";
import { Box } from "@mui/system";

export const SuccessAlert = ({ open, setOpen, msg, setMsg }) => {
  return (
    <Box sx={{ position: "absolute", top: 50, zIndex: 1000 }}>
      <Slide in={open} direction="down">
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
                setMsg(null);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {msg}
        </Alert>
      </Slide>
    </Box>
  );
};
