import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { deleteUser } from "../api/users";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export const DeleteModal = ({
  openDeleteModal,
  setOpenDeleteModal,
  selectedUserIdAndLabel,
  setSelectedUserIdAndLabel,
}) => {
  const handleConfirm = async () => {
    try {
      await deleteUser(selectedUserIdAndLabel.id);
      setOpenDeleteModal(false);
      setSelectedUserIdAndLabel(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
    setSelectedUserIdAndLabel(null);
  };

  return (
    <Modal
      open={openDeleteModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          textTransform="uppercase"
          textAlign="center"
        >
          Eliminar cliente
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2 }}
          textAlign="center"
        >
          {`¿Confirma que quiere ELIMINAR el cliente ${selectedUserIdAndLabel?.label.toUpperCase()}?`}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleConfirm}>Confirmar</Button>
        </Box>
      </Box>
    </Modal>
  );
};
