import React, { createContext, useState } from "react";
import axios from "axios";
import { signIn } from "../api/auth";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const AuthProvider = ({ children }) => {
  const [authError, setAuthError] = useState(null);
  const [user, setUser] = useState({
    loggedIn: false,
  });

  const login = async (credentials) => {
    try {
      const user = await signIn(credentials);
      if (user) {
        setSession(user?.token);
        setUser({
          loggedIn: true,
          token: user.token,
          email: user.user.email,
          label: user.user.label,
          rol: user.user.rol,
          isActive: user.user.isActive,
          id: user.user.id,
        });
      }
    } catch (error) {
      console.error(error);
      setAuthError(error.response.data.msg);
    }
  };

  const logout = () => {
    setUser({ loggedIn: false });
    setAuthError(null);
    setSession()
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        authError,
        setAuthError
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
