import React from "react";
import { Box, Container } from "@mui/system";
import LoginForm from "../components/LoginForm";
import { ErrorAlert } from "../components/ErrorAlert";

const Login = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        background: '#e4430d'
      }}
    >
      <Box>
        <img
          width="310"
          src="/logo-red.png"
          alt="Rueddo"
          style={{ margin: 20 }}
        />
      </Box>
      <Container
        maxWidth="xs"
        sx={{
          borderRadius: 1,
          padding: 3,
          boxShadow: "0px 6px 12px -2px rgba(0,0,0,0.38)",
          backgroundColor: "#FFF",
        }}
      >
        <LoginForm />
      </Container>
      <ErrorAlert />
    </Box>
  );
};

export default Login;
