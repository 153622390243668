import * as React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, MenuItem, TextField } from "@mui/material";
import { createUser, editUser } from "../api/users";

const roles = [
  {
    value: "CLIENT",
    label: "cliente",
  },
  {
    value: "ADMIN",
    label: "administrador",
  },
];

const userSchema = Yup.object().shape({
  id_cliente: Yup.string()
    .required("Required")
    .max(30, "ID must be less than 30 digits"),
  label: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .required("Required")
    .min(8, "Password must be at least 8 characters"),
  rol: Yup.string().required("Required"),
});

export const FormModal = ({ open, setOpen, setMsg, selectedUserData }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const initialValues = {
    id_cliente: "" || selectedUserData?.id_cliente,
    label: "" || selectedUserData?.label,
    email: "" || selectedUserData?.email,
    password: "" || selectedUserData?.password,
    rol: "" || selectedUserData?.rol,
  };

  const onSubmit = async (values) => {
    try {
      if (!selectedUserData) {
        const newUser = await createUser(values);
        if (newUser) {
          setMsg("Cliente creado con éxito!");
          handleClose();
        }
      } else {
        const editedUser = await editUser(selectedUserData.id, values);
        if (editedUser) {
          setMsg("Cliente editado con éxito!");
          handleClose();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="form-users-modal"
        aria-describedby="modal-for-create-or-edit-users"
      >
        <Box sx={style}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={userSchema}
          >
            {() => (
              <Form>
                <Field
                  as={TextField}
                  label="ID Cliente"
                  name="id_cliente"
                  fullWidth
                  margin="normal"
                  size="small"
                />
                <ErrorMessage name="id_cliente">
                  {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                </ErrorMessage>
                <Field
                  as={TextField}
                  label="Cliente"
                  name="label"
                  fullWidth
                  margin="normal"
                  size="small"
                />
                <ErrorMessage name="label">
                  {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                </ErrorMessage>
                <Field
                  as={TextField}
                  label="Email"
                  name="email"
                  fullWidth
                  margin="normal"
                  size="small"
                />
                <ErrorMessage name="email">
                  {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                </ErrorMessage>
                {!selectedUserData && (
                  <>
                    <Field
                      as={TextField}
                      label="Contraseña"
                      name="password"
                      type="password"
                      fullWidth
                      margin="normal"
                      size="small"
                    />
                    <ErrorMessage name="password">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </>
                )}
                <Field
                  as={TextField}
                  select
                  label="Rol"
                  name="rol"
                  type="rol"
                  fullWidth
                  margin="normal"
                  size="small"
                >
                  {roles.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <ErrorMessage name="rol">
                  {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                </ErrorMessage>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ width: "100%", mt: 2 }}
                >
                  {selectedUserData ? "Editar usuario" : "Crear usuario"}
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#FFF",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
