import * as React from "react";
import { Box } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Chip, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from "@mui/icons-material/ToggleOff";

const columns = [
  { id: "label", label: "Nombre", minWidth: 170, align: "left" },
  { id: "email", label: "Email", minWidth: 200, align: "left" },
  { id: "isActive", label: "Estado", minWidth: 200, align: "center" },
  {
    id: "actions",
    label: "Acciones",
    minWidth: 70,
    align: "right",
  },
];

export const UsersTable = ({
  data,
  setOpenModal,
  setSelectedUserData,
  setOpenDisableModal,
  setOpenDeleteModal,
  setSelectedUserIdAndLabel,
  setOpenEnableModal
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (user) => {
    setSelectedUserData(user);
    setOpenModal(true);
  };

  const handleDisable = (user) => {
    setSelectedUserIdAndLabel({ id: user.id, label: user.label });
    setOpenDisableModal(true);
  };

  const handleEnable = (user) => {
    setSelectedUserIdAndLabel({ id: user.id, label: user.label });
    setSelectedUserData(user);
    setOpenEnableModal(true);
  };

  const handleDelete = (user) => {
    setSelectedUserIdAndLabel({ id: user.id, label: user.label });
    setOpenDeleteModal(true);
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: "85vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .sort((a, b) => a.label.localeCompare(b.label))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={user.id}>
                    {columns.map((column) => {
                      const value = user[column.id];
                      return column.id !== "actions" ? (
                        <TableCell key={column.id} align={column.align}>
                          {typeof value === "boolean" ? (
                            <Chip
                              label={value === true ? "activo" : "inactivo"}
                              color={value === true ? "primary" : "default"}
                              size="small"
                              variant={value === true ? "filled" : "outlined"}
                            />
                          ) : (
                            value
                          )}
                        </TableCell>
                      ) : (
                        <TableCell key={column.id} align={column.align}>
                          {user.isActive ? (
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              onClick={() => handleDisable(user)}
                            >
                              <ToggleOnIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              aria-label="enable"
                              color="default"
                              onClick={() => handleEnable(user)}
                            >
                              <ToggleOffIcon />
                            </IconButton>
                          )}
                          <IconButton
                            disabled={!user.isActive}
                            aria-label="edit"
                            color="primary"
                            onClick={() => handleEdit(user)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            // disabled={user.isActive}
                            aria-label="edit"
                            color="default"
                            onClick={() => handleDelete(user)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ position: "fixed", bottom: 0, width: "100%" }}
      />
    </Box>
  );
};
