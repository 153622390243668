import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, TextField } from "@mui/material";
import useAuth from "../hooks/useAuth";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required").min(8, 'Password must be at least 8 characters'),
});

const LoginForm = () => {
  const {login} = useAuth()

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = (values) => {
    login(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={LoginSchema}
    >
      {() => (
        <Form>
          <Field
            as={TextField}
            label="Email"
            name="email"
            fullWidth
            margin="normal"
          />
          <ErrorMessage name="email">
            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
          </ErrorMessage>
          <Field
            as={TextField}
            label="Password"
            name="password"
            type="password"
            fullWidth
            margin="normal"
          />
          <ErrorMessage name="password">
            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
          </ErrorMessage>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{width: '100%', mt: 2}}
          >
            Iniciar sesión
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
