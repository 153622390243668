import axios from "axios";
import { USERS_PATH } from "./config";

export const listAllUsers = async () => {
  const response = await axios.get(`${USERS_PATH}`);
  return response.data;
};

export const createUser = async (user) => {
    const response = await axios.post(`${USERS_PATH}`, user);
    return response.data
};

export const editUser = async (id, user) => {
  const response = await axios.put(`${USERS_PATH}/${id}`, user)
  return response.data
}

export const disableUser = async (id, user) => {
  const response = await axios.put(`${USERS_PATH}/${id}`, {...user, isActive: false})
  return response.data
}

export const deleteUser = async (id) => {
  const response = await axios.delete(`${USERS_PATH}/${id}`)
  return response.data
}
