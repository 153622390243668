import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { editUser } from "../api/users";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export const EnableModal = ({
  openEnableModal,
  setOpenEnableModal,
  selectedUserData,
  setSelectedUserData,
}) => {
  const handleConfirm = async () => {
    try {
      delete selectedUserData.password;
      await editUser(selectedUserData.id, {
        ...selectedUserData,
        isActive: true,
      });
      setOpenEnableModal(false);
      setSelectedUserData(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpenEnableModal(false);
    setSelectedUserData(null);
  };

  return (
    <Modal
      open={openEnableModal}
      onClose={handleClose}
      aria-labelledby="modal"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          textTransform="uppercase"
          textAlign="center"
        >
          Habilitar cliente
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2 }}
          textAlign="center"
        >
          {`¿Confirma que quiere cambiar el estado del cliente ${selectedUserData?.label.toUpperCase()} a activo?`}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleConfirm}>Confirmar</Button>
        </Box>
      </Box>
    </Modal>
  );
};
